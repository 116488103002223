import styles from "./index.module.scss";
import { fetchGoData } from "../../utils/Methods";
import ProgressBar from "../stepper";
import backIcon from "../../assets/newBackIcon.svg";

const Header = ({
  filled = 0,
  hasBackButton,
  handleBack,
}: {
  filled: number;
  hasBackButton?: boolean;
  handleBack?: () => void;
}) => {
  const selectedOutlet = fetchGoData("selectedOutlet");

  return (
    <>
      <div className={styles.container}>
        {/* {hasBackButton && (
          <div className={styles.backArrowContainer}>
            <img
              onClick={handleBack}
              className={styles.backArrow}
              src={backIcon}
              alt="logo"
            />
          </div>
        )} */}

        <div className={styles.headerBrandLogo}>
          {hasBackButton && (
            <img
              onClick={handleBack}
              className={styles.backArrow}
              src={backIcon}
              alt="logo"
            />
          )}
          {selectedOutlet?.brandLogo && (
            <img
              className={styles.outletLogo}
              src={selectedOutlet?.brandLogo}
              alt="logo"
            />
          )}
        </div>
      </div>
      <div>
        <ProgressBar items={4} currentStep={filled} />
      </div>
    </>
  );
};
export default Header;
